<template>
    <div>
      <v-layout wrap justify-center pa-4 pa-lg-10 pb-lg-16>
        <v-flex xs12 text-center py-5>
        <span
          class="bold"
          style="
            border-bottom: 5px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
          "
        >
        Affidavit</span
        >
      </v-flex>

      <v-flex lg12 pt-7 style="height: 200px;">
  <v-layout row wrap justify-center>
    <v-flex
      v-for="(button, index) in buttons"
      :key="index"
      lg3
      class="d-flex align-center"
    >
      <a
        v-if="button.href"
        :href="button.href"
        target="_blank"
        style="text-decoration: none; color: black"
      >
        <v-btn style="width: 300px;" class="ma-2" color="primary">
          {{ button.label }}
        </v-btn>
      </a>
      <v-btn
        v-else
        class="ma-2"
        color="primary"
        @click="handleButtonClick(button)"
      >
        {{ button.label }}
      </v-btn>
    </v-flex>
  </v-layout>
</v-flex>



      </v-layout>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      // Array of buttons with labels
      buttons: [
        { label: "Affidavit 2021-2022", href: "./../../assets/Affidavit2021.pdf" },
        { label: "Affidavit 2022-2023", href: "./../../assets/Affidavit2022_merged.pdf" },
      ]
    };
  },
  methods: {
    // Handle button click for normal buttons
    handleButtonClick(button) {
      alert(`You clicked: ${button.label}`);
    }
  },
};
</script>

  <!-- <style>
  table {
    font-family: pbold;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
    font-family: pregular;
  }
  tr {
    font-family: pregular;
  }
  .LH {
    line-height: 30px;
  }
  </style> -->